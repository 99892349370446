/* eslint-disable @typescript-eslint/no-explicit-any */
export const get = <T>(obj: T, path: string | string[]): any => {
  if (typeof path === 'string') {
    path = path.split('.').filter(part => part.length > 0);
  }
  return path.reduce((acc: any, ck: string) => acc && acc[ck], obj);
};

export const waitFor = (
  selector: string,
  callback: () => void,
): Promise<void> => {
  return new Promise<void>(resolve => {
    const interval = setInterval(() => {
      if (get(window, selector)) {
        clearInterval(interval);
        callback();
        resolve();
      }
    }, 20);
  });
};

const waitForCondition = <T = unknown>(
  conditionFn: () => boolean,
  callback: () => Promise<T | undefined>,
  errorCb: () => void,
  checkIntervalMs = 20,
  timeoutMs = 5000,
): Promise<T | undefined> => {
  let interval: NodeJS.Timeout;
  return new Promise<T | undefined>(resolve => {
    const timeout = setTimeout(() => {
      clearInterval(interval);
      errorCb();
      resolve(undefined);
    }, timeoutMs);
    interval = setInterval(async () => {
      if (conditionFn()) {
        clearTimeout(timeout);
        clearInterval(interval);
        const res = await callback();
        resolve(res);
      }
    }, checkIntervalMs);
  });
};

const initGlobalUtils = () => {
  window.get = get;
  window.waitFor = waitFor;
  window.waitForCondition = waitForCondition;
};

initGlobalUtils();
